import revive_payload_client_9HD67rTNZzXdahe1954KthTQz2a8gHjrGCmQL4eJLIQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.3.1_encoding@0.1.13_eslint@9.22._5wmlgm2vw7txqht7yhovhjixme/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KfJWFlaeKV2_t75C_3megkp4p6PUEY4v1kRb0yqrt6M from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.3.1_encoding@0.1.13_eslint@9.22._5wmlgm2vw7txqht7yhovhjixme/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_P3mxGy_7RiUTXUSpzvfbJIPM0COpMS1mN7JvxUmoT5M from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.3.1_encoding@0.1.13_eslint@9.22._5wmlgm2vw7txqht7yhovhjixme/node_modules/nuxt/dist/app/plugins/router.js";
import supabase_client_no0NtLZ9smb9h5JXyu0FjQPCTx_KZfOxFyGtFfOag_g from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_8SXUVE85Hkd9UITuAeuQKrB4Ed8usiNJrEsT38HVrp4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.3.1_encoding@0.1.13_eslint@9.22._5wmlgm2vw7txqht7yhovhjixme/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GlRqIHroSZjCqIbsMBgGUliijgPm7_RJAjWBW2NIXwI from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.3.1_encoding@0.1.13_eslint@9.22._5wmlgm2vw7txqht7yhovhjixme/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_okOK4U2UHwRA95PLWxKl_Gg117GIWhM4wq89O4xSO2Q from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.3.1_encoding@0.1.13_eslint@9.22._5wmlgm2vw7txqht7yhovhjixme/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_BA3k5AFmyn6qerpUYDmWZtPfUm_iZNb6M5hREkjODxE from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.3.1_encoding@0.1.13_eslint@9.22._5wmlgm2vw7txqht7yhovhjixme/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_2fvwzbPBkD6PBtI_0MfWXgQHBK9lLERZUMvrm50YaqE from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.36.0_vite@6.2.2_@types+node@22.10.10_jiti@2.4.2_a4njv2b56qfuzaoxpfu5hxpyne/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_rx_z8goDINy53c6smCVTkq4zIF5pCA_zHY3TTqFLeJ0 from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.36.0_vite@6.2.2_@types+node@22.10.10_jiti@2.4.2_ter_kn2y7gnz6diznio725oz3oxr4q/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import fathom_client_8YdsWctdjP_mjBpVD8oOeGNNrHyDk2rfLUHgmpdDuYc from "/vercel/path0/plugins/fathom.client.ts";
import init_client_LfLCQXChc0kvp7wJ33XE9wD0zunz8_sUTQCK0GTf5Jw from "/vercel/path0/plugins/init.client.ts";
export default [
  revive_payload_client_9HD67rTNZzXdahe1954KthTQz2a8gHjrGCmQL4eJLIQ,
  unhead_KfJWFlaeKV2_t75C_3megkp4p6PUEY4v1kRb0yqrt6M,
  router_P3mxGy_7RiUTXUSpzvfbJIPM0COpMS1mN7JvxUmoT5M,
  supabase_client_no0NtLZ9smb9h5JXyu0FjQPCTx_KZfOxFyGtFfOag_g,
  payload_client_8SXUVE85Hkd9UITuAeuQKrB4Ed8usiNJrEsT38HVrp4,
  navigation_repaint_client_GlRqIHroSZjCqIbsMBgGUliijgPm7_RJAjWBW2NIXwI,
  check_outdated_build_client_okOK4U2UHwRA95PLWxKl_Gg117GIWhM4wq89O4xSO2Q,
  chunk_reload_client_BA3k5AFmyn6qerpUYDmWZtPfUm_iZNb6M5hREkjODxE,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_2fvwzbPBkD6PBtI_0MfWXgQHBK9lLERZUMvrm50YaqE,
  plugin_rx_z8goDINy53c6smCVTkq4zIF5pCA_zHY3TTqFLeJ0,
  fathom_client_8YdsWctdjP_mjBpVD8oOeGNNrHyDk2rfLUHgmpdDuYc,
  init_client_LfLCQXChc0kvp7wJ33XE9wD0zunz8_sUTQCK0GTf5Jw
]